<template>
  <el-card class="box-card">
    <el-row :gutter="20">
      <el-col :lg="8" :md="12" :sm="24" :xl="5" :xs="24">
        <el-card shadow="hover">
          <div class="personal-center-user-info">
            <div class="center">
              <el-avatar
                  :size="100"
                  :src="user.pic"
              />
              <div class="personal-center-user-info-full-name">
                {{ user.name }}
              </div>
              <!--              <div class="personal-center-user-info-description">
                              {{ user.name }}
                            </div>-->
              <div class="personal-center-user-info-follow">
                <el-button round type="primary" size="small">
                  <i class="el-icon-user"></i>
                  {{ user.user_level ? user.user_level.name : '未知等级' }}
                </el-button>
              </div>
            </div>

            <ul class="personal-center-user-info-list">
              <li>
                <i class="el-icon-phone-outline"></i>
                {{ user.phone }}
              </li>
              <li>
                <i class="el-icon-eleme"></i>
                {{ user.email }}
              </li>
              <li>
                <i class="el-icon-star-off"></i>
                注册时间:{{ user.create_at | formatDate }}
              </li>
              <li>
                <i class="el-icon-location-outline"></i>
                最后登录IP:{{ user.last_ip }}
              </li>
              <el-divider content-position="left">财务</el-divider>
              <li>
                <i class="el-icon-coin"></i>
                余额:{{ user.user_wallet ? user.user_wallet.money : '-' }} 元
              </li>
              <li>
                <i class="el-icon-money"></i>
                可用积分:{{ user.user_wallet ? user.user_wallet.credit : '-' }} 元
              </li>
              <li>
                <i class="el-icon-medal"></i>
                等级积分:{{ user.user_wallet ? user.user_wallet.level_credit : '-' }} 分
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :lg="16" :md="12" :sm="24" :xl="19" :xs="24">
        <el-card shadow="hover">
          <el-divider content-position="left">基本信息</el-divider>
          <el-form
              ref="form"
              label-width="80px"
              :model="form"
              :rules="rules"
          >
            <el-form-item label="姓名" prop="name" class="top">
              <el-input v-model="form.name" size="small" style="width: 25%;" placeholder="请输入用户名"/>
            </el-form-item>
            <el-form-item label="性别" class="top">
              <el-select v-model="form.sex" size="small" style="width: 25%;" placeholder="请选择用户性别">
                <el-option label="保密" :value="0"/>
                <el-option label="男" :value="1"/>
                <el-option label="女" :value="2"/>
              </el-select>
            </el-form-item>
            <!--            <el-form-item label="卡号" prop="card_sn" class="top">
                          <el-input v-model="form.card_sn" size="small" style="width: 25%;" placeholder="请输入卡号"/>
                        </el-form-item>-->
            <el-form-item label="手机号码" prop="phone" class="top">
              <el-input v-model="form.phone" size="small" style="width: 25%;" placeholder="请输入11位手机号码"/>
            </el-form-item>
            <el-form-item label="邮箱" prop="email" class="top">
              <el-input v-model="form.email" size="small" style="width: 25%;" placeholder="请输入邮箱"/>
            </el-form-item>
            <!--            <el-form-item label="分销" class="top">
                          <el-switch
                              v-model="form.is_plus"
                              active-color="#13ce66"
                              active-text="是"
                              :active-value="1"
                              inactive-color="#dcdfe6"
                              inactive-text="否"
                              :inactive-value="0"
                          />
                        </el-form-item>-->
            <el-form-item label="锁定" class="top">
              <el-switch
                  v-model="form.is_lock"
                  active-color="#13ce66"
                  active-text="是"
                  :active-value="1"
                  inactive-color="#dcdfe6"
                  inactive-text="否"
                  :inactive-value="0"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm" size="small" class="top">
                保存
              </el-button>
            </el-form-item>
          </el-form>
          <el-divider content-position="left">账户金额</el-divider>
          <el-container>
            <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
              <div class="personal-center-item">
                <vab-icon
                    class="el-icon-money i_tag"
                    style="color: #3492ed"
                />
                <div class="personal-center-item-content">
                  <div>账户余额</div>
                  <div class="personal-center-item-content-second">
                    {{ user.user_wallet ? user.user_wallet.money : '-' }} 元
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
              <div class="personal-center-item">
                <vab-icon
                    class="el-icon-coin i_tag"
                    style="color: #e4e7ed"
                />
                <div class="personal-center-item-content">
                  <div>冻结金额</div>
                  <div class="personal-center-item-content-second">
                    {{ user.user_wallet ? user.user_wallet.frozen_money : '-' }} 元
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
              <div class="personal-center-item">
                <vab-icon
                    class="el-icon-sell i_tag"
                    style="color: #4169e1"
                />
                <div class="personal-center-item-content">
                  <div>累计使用金额</div>
                  <div class="personal-center-item-content-second">
                    {{ user.user_wallet ? user.user_wallet.total_use_money : '-' }} 元
                  </div>
                </div>
              </div>
            </el-col>
          </el-container>
          <el-divider/>
          <el-container>
            <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
              <div class="personal-center-item">
                <vab-icon
                    class="el-icon-medal i_tag"
                    style="color: #7b68ee"
                />
                <div class="personal-center-item-content">
                  <div>可用积分</div>
                  <div class="personal-center-item-content-second">
                    {{ user.user_wallet ? user.user_wallet.credit : '-' }} 分
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
              <div class="personal-center-item">
                <vab-icon
                    class="el-icon-medal-1 i_tag"
                    style="color: #6495ed"
                />
                <div class="personal-center-item-content">
                  <div>赠送积分</div>
                  <div class="personal-center-item-content-second">
                    {{ user.user_wallet ? user.user_wallet.give_credit : '-' }} 分
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
              <div class="personal-center-item">
                <vab-icon
                    class="el-icon-first-aid-kit i_tag"
                    style="color: #1e90ff"
                />
                <div class="personal-center-item-content">
                  <div>累计使用积分</div>
                  <div class="personal-center-item-content-second">
                    {{ user.user_wallet ? user.user_wallet.total_use_credit : '-' }} 分
                  </div>
                </div>
              </div>
            </el-col>
          </el-container>
          <el-divider/>
          <el-container>
            <el-col :lg="8" :md="8" :sm="8" :xl="8" :xs="8">
              <div class="personal-center-item">
                <vab-icon
                    class="el-icon-position i_tag"
                    style="color: #00bfff"
                />
                <div class="personal-center-item-content">
                  <div>等级积分</div>
                  <div class="personal-center-item-content-second">
                    {{ user.user_wallet ? user.user_wallet.level_credit : '-' }} 分
                  </div>
                </div>
              </div>
            </el-col>
          </el-container>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import {apiGetUserInfo, apiUpdateUserInfo} from '@/request/api'

export default {
  name: 'userDetails',
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  data() {
    return {
      activeName: '1',
      user: {},
      id: this.$route.query.id,
      form: {},
      rules: {
        name: [
          {required: true, message: '请输入名字', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {
            min: 11,
            max: 11,
            message: '请正确输入手机号码',
            trigger: 'blur',
          },
        ],
        email: [
          {required: true, message: '请输入邮箱', trigger: 'blur'},
          {
            type: 'email',
            message: '请输入正确的邮箱',
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  created() {
    this.requestDemo()
  },
  methods: {
    //获取用户详情
    async requestDemo() {
      apiGetUserInfo({role: 'user', id: this.$route.query.id}).then(res => {
        if (res.code == 200) {
          console.log(res.data)
          this.user = res.data
          this.form = {
            id: res.data.id,
            name: res.data.name,
            sex: res.data.sex,
            phone: res.data.phone,
            email: res.data.email,
            is_lock: parseInt(res.data.is_lock),
          }
        }
      })
    },
    // 修改个人信息
    submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiUpdateUserInfo(this.form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.requestDemo()
            } else {
              this.$message.error(res.message)
              this.requestDemo()
            }
          })
        } else {
          return false
        }
      })
    },
  }
}
</script>
<style>
.center {
  text-align: center;
}

.personal-center-user-info-full-name {
  margin-top: 15px;
  font-size: 24px;
  font-weight: 500;
  color: #262626;
}

.personal-center-user-info-follow {
  margin-top: 8px;
}

.personal-center-user-info-list {
  margin-top: 18px;
  line-height: 30px;
  text-align: left;
  list-style: none;
  font-size: 14px;
  color: #626b7d;
}

.top {
  margin-top: 15px;
}

.personal-center-item {
  display: flex;
}

.i_tag {
  font-size: 40px;
}

.personal-center-item-content {
  box-sizing: border-box;
  flex: 1;
  margin-left: 20px;
  font-size: 13px;
}

.personal-center-item-content .personal-center-item-content-second {
  margin-top: 8px;
}
</style>
